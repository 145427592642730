import React, { useContext, useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import { FBToolContext } from 'src/pages/FBTool/index';
import DSelect from 'src/components/Form/DSelect';
import { getPageVideos, show_permalink_url } from 'src/pages/FBTool/functions';
import { show_date } from 'src/helpers/date';

function FbToolContent(props) {

    const {
        accessToken,
        pages,
        setSelectedPage,
        setParams,
        adAccounts,
        isLoading,
        getAdsTokens,
    } = useContext(FBToolContext);
    const selectPages = Object.keys(pages).map((key) => {
        return { value: key, label: pages[key].name };
    });

    const selectAdAccounts = Object.keys(adAccounts).map((key) => {
        return { value: key, label: adAccounts[key].name + ` - ${key}` };
    });

    const [pageVideos, setPageVideos] = useState([]);

    useEffect(() => {
        setSelectedRows([]);
    }, [pageVideos]);

    const [selectedRows, setSelectedRows] = useState([]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const formDataObject = new FormData(e.target);
        const formData = Object.fromEntries(formDataObject.entries());
        const adToken = getAdsTokens(formData.act_id);

        const videos = await getPageVideos({
            token: accessToken,
            page_id: formData.page_id,
            act_id: formData.act_id,
            limit: formData.limit,
        });
        setPageVideos(videos);


    };

    function handleSelectAll(e) {
        if (e.target.checked) {
            setSelectedRows(pageVideos.map((video) => video.id));
        } else {
            setSelectedRows([]);
        }
    }

    function handleSelectRow(e, video) {
        if (e.target.checked) {
            setSelectedRows([...selectedRows, video.id]);
        } else {
            setSelectedRows(selectedRows.filter((row) => row !== video.id));
        }
    }

    return (
        <>
            <CCard className="mb-4">
                <CCardHeader>Facebook Tools </CCardHeader>
                <CCardBody className="pb-0">
                    <form className="row mb-4" onSubmit={handleFormSubmit}>
                        <div className="col-3">
                            <label htmlFor="">Pages</label>
                            <DSelect name="page_id" options={selectPages}></DSelect>
                        </div>

                        <div className="col-3">
                            <label htmlFor="">Ad Accounts</label>
                            <DSelect name="act_id" options={selectAdAccounts}></DSelect>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Limit</label>
                            <input type="number" name="limit" className="form-control" defaultValue="10" />
                        </div>

                        <div className="col-12 mt-2">
                            <CButton type="submit" className="btn-sm" disabled={isLoading}>
                                Search
                            </CButton>
                        </div>
                    </form>
                </CCardBody>
                <CCardBody className="pt-0">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th className={'col-form-label-sm'}>
                                <label className={'me-1'}>No.</label>
                                <input className={'form-check-input'} type="checkbox" onChange={handleSelectAll}
                                       checked={selectedRows.length === pageVideos.length} />
                            </th>
                            <th>Videos</th>
                            <th>Created Time</th>
                            <th>Logs</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pageVideos.map((video, index) => (
                            <tr key={video.id}>
                                <td className={'col-form-label-sm'}>
                                    <label className={'me-1'}>{index + 1}. </label>
                                    <input className={'form-check-input col-form-label-sm'} type="checkbox"
                                           onChange={(e) => handleSelectRow(e, video)}
                                           checked={selectedRows.includes(video.id)} />
                                </td>
                                <td>
                                    <div className="mb-2">
                                        <a href={show_permalink_url(video.permalink_url)}
                                           className={'btn btn-success btn-sm me-2'} target="_blank">Link
                                        </a>
                                        <a
                                            className={'btn btn-success btn-sm'}
                                            target={'_blank'}
                                            href={video.source}
                                        >Source</a>
                                    </div>
                                    <div className="thumbnails">
                                        {(video.thumbnails.data ?? []).map((thumb, thumbIndex) => {
                                            if (thumbIndex > 2) return null;
                                            return (
                                                <img
                                                    style={{
                                                        marginRight: 5,
                                                        objectFit: 'contain',
                                                    }}
                                                    key={thumbIndex}
                                                    src={thumb.uri}
                                                    height={100}
                                                    alt={video.title ?? 'Video'}
                                                    className="thumb"
                                                />
                                            );
                                        })}
                                    </div>
                                    <textarea style={{
                                        marginTop: 10,
                                    }}
                                              className="form-control"
                                              defaultValue={video.description}

                                    >{video.description}</textarea>

                                </td>
                                <td>{show_date(video.created_time)}</td>
                                <td>

                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </CCardBody>
            </CCard>
        </>
    );
}

export default FbToolContent;