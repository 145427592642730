import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { useRef, useState, useEffect } from 'react';
import notify from 'src/utils/notify';

const ImagePost = () => {
    const inputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [accessToken, setAccessToken] = useState(""); 
    const [adsAccessToken, setAdsAccessToken] = useState(""); 
    const [userInfo, setUserInfo] = useState([]); 
    const [pagesData, setPages] = useState([]); 
    const [selectedPage, setSelectedPage] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [imageUrl, setImageUrl] = useState(""); 
    const [fakeDomain, setFakeDomain] = useState("");
    const [link, setLink] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [buttonType, setButtonType] = useState("LEARN_MORE");
    const [status, setStatus] = useState("");
    const [idAds, setIdAds] = useState("");
    const [urlPost, setUrlPost] = useState("");
    const selectedPageRef = useRef(null);
    const idPost = useRef(null);
    const loadingRef = useRef(false);
    // Hàm xử lý khi người dùng nhập vào các input
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "fakedomain") setFakeDomain(value);
        if (name === "link") setLink(value);
        if (name === "title") setTitle(value);
        if (name === "description") setDescription(value);
        if (name === "type_button") setButtonType(value);
        if (name === "status") setStatus(value);
    };

    const handleImageChange = () => {
        const file = inputRef.current.files[0]; 
    
        if (file) {
            setImage(URL.createObjectURL(file)); 
            console.log("Selected file:", file);
            loadingRef.current = true; // Bật trạng thái loading
            notify.success('Upload...');

            window.dispatchEvent(new CustomEvent("uploadImage", {
                detail: { files: file } 
            }));
        } else {
            notify.warning('Vui lòng chọn một hình ảnh');
        }
    };

    const handleAccountChange = (event) => {
        const accountId = event.target.value;
        const account = userInfo.find(info => info.account_id === accountId);
        setSelectedAccount(account); 
    };

    const requestAccessToken = () => {
        loadingRef.current = true; // Bật trạng thái loading
        window.dispatchEvent(new CustomEvent("getAccessToken"));
    };

    const requestgetCookiesFB = () => {
        window.dispatchEvent(new CustomEvent("getCookiesFB"));
    };

    const requestAdsAccessToken = () => {
        window.dispatchEvent(new CustomEvent("getAdsAccessToken"));
    };

    const requestgetPages = () => {
        window.dispatchEvent(new CustomEvent("getPages"));
    };
    const requestCreatPost = () => { 
        if (!selectedPage) {
            notify.error("Please Select Page");
            return;
        }
        loadingRef.current = true; // Bật trạng thái loading
        const postPicture = imageUrl;
        const postMessage = status;
        const postDescription = description;
        const postLink = link;
        const postName = title;
        const postCaption = fakeDomain;
        const postButtonType = buttonType;
        const postPageid = selectedPage.id;
    
        console.log({
            picture: postPicture,
            message: postMessage,
            description: postDescription,
            link: postLink,
            name: postName,
            caption: postCaption,
            type: postButtonType,
            page_id: postPageid,
        });
        notify.success('Creating Post...');
        window.dispatchEvent(new CustomEvent("creatPost", {
            detail: { 
                picture: postPicture,
                message: postMessage,
                description: postDescription,
                link: postLink,
                name: postName,
                caption: postCaption,
                type: postButtonType,
                page_id: postPageid,
            } 
        }));
    };
    const requestGetAdsPost = (adId) => { 
        console.log({
            idPostAds: adId 
        });
    
        window.dispatchEvent(new CustomEvent("getAdsPost", {
            detail: { 
                id_Ads: adId 
            } 
        }));
    };
    const requestPublishAdsPost = (postID) => { 
        if (selectedPageRef.current) {
            console.log('Selected Page:', selectedPageRef.current); // Kiểm tra giá trị của selectedPageRef
            if (selectedPageRef.current.access_token) {
                console.log('Publishing with access_token:', selectedPageRef.current.access_token);
                window.dispatchEvent(new CustomEvent("publishAdsPost", {
                    detail: { 
                        id_Post: postID,
                        page_token: selectedPageRef.current.access_token
                    } 
                }));
            } else {
                notify.error('Please Select Page.');
            }
        } else {
            notify.error('Please Select Page');
        }
    };



    useEffect(() => {
        requestAccessToken();
        requestgetCookiesFB();
        requestAdsAccessToken();
        requestgetPages();

        const handleAccessTokenResponse = (event) => {
            const token = event.detail.accessToken;
            if (token) {
                setAccessToken(token); 
                //notify.success('Đã lấy được Access Token');
                window.dispatchEvent(new CustomEvent("getUserInfo"));
            } else {
                notify.error('Can Not Get Access Token');
            }
        };

        const handleUserInfoResponse = (event) => {
            const data = event.detail;
            if (Array.isArray(data)) {
                setUserInfo(data); 
                //notify.success("Đã lấy được thông tin người dùng");
            } else {
                notify.error("Can Not Get UserInfo");
            }
        };

        const handleAdsAccessTokenResponse = (event) => {
            const adstoken = event.detail.adsAccessToken;
            if (adstoken) {
                setAdsAccessToken(adstoken); 
                //notify.success('Đã lấy được Ads Access Token');
            } else {
                notify.error('Can Not Get Ads Access Token');
            }
        };

        const handleGetPagesResponse = (event) => {
            const pagesData = event.detail;
            if (Array.isArray(pagesData)) {
                setPages(pagesData);
                loadingRef.current = false; // Bật trạng thái loading
                notify.success('Get List Page Done');
            } else {
                notify.error('Can Not Get List Page. Please Reload');
            }
        };

        const handleuploadImageResponse = (event) => {
            const imageData = event.detail;

            if (imageData && imageData.images && typeof imageData.images === "object") {
                const fileName = Object.keys(imageData.images)[0]; 
                const fileData = imageData.images[fileName]; 

                if (fileData) {
                    setImageUrl(fileData.url); 
                    notify.success('Upload Success');
                    loadingRef.current = false; // Bật trạng thái loading
                    console.log("Hash của ảnh:", fileData.hash);
                } else {
                    notify.error('Can Not Upload.');
                }
            } else {
                notify.error('Some Error.');
            }
        };
        const handleCreatPostResponse = (event) => {
            const dataAds = event.detail;
        
            // Kiểm tra xem dữ liệu trả về có chứa trường `id` không
            if (dataAds && dataAds.id) {
                // Hiển thị thông báo thành công và log id
                setIdAds(dataAds.id);
                console.log("Ad ID:", dataAds.id);
                requestGetAdsPost(dataAds.id);
            } else {
                notify.error("Can Not Creat Ads Post");
            }
        };
        const handleGetAdsPostResponse = (event) => {
            const dataAdsPost = event.detail;
            if (dataAdsPost && dataAdsPost.effective_object_story_id) {
                console.log("Ad ID Post:", dataAdsPost.effective_object_story_id);
                //setIdPost(dataAdsPost.effective_object_story_id);
                idPost.current = dataAdsPost.effective_object_story_id
                requestPublishAdsPost(dataAdsPost.effective_object_story_id);
            } else {
                loadingRef.current = false;
                notify.error("Lỗi khi tạo ADS Post.");
            }
        };
        const handlePublishAdsPostResponse = (event) => {
            const dataPubPost = event.detail;
            
            if (dataPubPost && dataPubPost.success) {
                loadingRef.current = false; // Tắt trạng thái loading
                const url = "https://www.facebook.com/"+idPost.current;
                setUrlPost(url);
                notify.success('Tạo Post Thành Công');
            } else {
                loadingRef.current = false;
                notify.error("Không Tạo Được Post.");
            }
        };

        window.addEventListener("getAccessTokenResponse", handleAccessTokenResponse);
        window.addEventListener("getUserInfoResponse", handleUserInfoResponse);
        window.addEventListener("getAdsAccessTokenResponse", handleAdsAccessTokenResponse);
        window.addEventListener("getPagesResponse", handleGetPagesResponse);
        window.addEventListener("uploadImageResponse", handleuploadImageResponse);
        window.addEventListener("creatPostResponse", handleCreatPostResponse);
        window.addEventListener("getAdsPostResponse", handleGetAdsPostResponse);
        window.addEventListener("publishAdsPostResponse", handlePublishAdsPostResponse);

        return () => {
            window.removeEventListener("getAccessTokenResponse", handleAccessTokenResponse);
            window.removeEventListener("getUserInfoResponse", handleUserInfoResponse);
            window.removeEventListener("getAdsAccessTokenResponse", handleAdsAccessTokenResponse);
            window.removeEventListener("getPagesResponse", handleGetPagesResponse);
            window.removeEventListener("uploadImageResponse", handleuploadImageResponse);
            window.removeEventListener("creatPostResponse", handleCreatPostResponse);
            window.removeEventListener("getAdsPostResponse", handleGetAdsPostResponse);
            window.removeEventListener("publishAdsPostResponse", handlePublishAdsPostResponse);
        };
    }, []);

    

    const handlePageChange = (event) => {
        const pageId = event.target.value;
        const page = pagesData.find(page => page.id === pageId);
        
        if (page) {
            selectedPageRef.current = page; // Lưu thông tin trang vào ref
            setSelectedPage(page);
            console.log('Page selected:', page); 
        } else {
            selectedPageRef.current = null; // Xóa nếu không có trang nào được chọn
            notify.error('Trang không tồn tại.');
        }
    };


    return (
        <CCard>
            <CCardHeader>
                Post Image With Link
            </CCardHeader>
            <CCardBody>
            <div className="row row5" style={{ maxWidth: '1024px' }}>
                <div className="col-12 col-lg-4  mb-3">
                    <div className="card">
                        <label htmlFor="page-select">Trang</label>
                        <select className="form-select" id="page-select" onChange={handlePageChange} defaultValue="" style={{ width: 'auto' }}>
                            <option value="" disabled>
                                Select Page
                            </option>
                            {pagesData.map((page, index) => (
                                <option key={index} value={page.id}>
                                    {page.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="card">
                        <label htmlFor="account-select">Ads Account</label>
                        <select className="form-select" id="account-select" onChange={handleAccountChange} defaultValue="" style={{ width: 'auto' }}>
                            <option value="" disabled>
                                Select Account
                            </option>
                            {userInfo.map((account, index) => (
                                <option key={index} value={account.account_id}>
                                    {account.account_id}
                                </option>
                            ))}
                        </select>
                    </div>
                    <input 
                    type="file" 
                    ref={inputRef} 
                    accept="image/*" 
                    style={{ display: 'none' }} 
                    onChange={handleImageChange} 
                    />
                    <button className="btn btn-primary" onClick={() => inputRef.current.click()}>
                        Select Image
                    </button>
                    <div className="card">
                        <label htmlFor="fakedomain">Display domain</label>
                        <input className="form-control" type="text" name="fakedomain" onChange={handleInputChange} />
                    </div>
                    <div className="card">
                        <label htmlFor="link">Link</label>
                        <input className="form-control" type="text" name="link" onChange={handleInputChange} />
                    </div>
                    <div className="card">
                        <label htmlFor="title">Title</label>
                        <input className="form-control" type="text" name="title" onChange={handleInputChange} />
                    </div>
                    <div className="card">
                        <label htmlFor="description">Description</label>
                        <textarea className="form-control" name="description" onChange={handleInputChange} />
                    </div>
                    <div className="card">
                        <label htmlFor="Card-select">Card button</label>
                        <select className="form-select" name="type_button" defaultValue="LEARN_MORE" onChange={handleInputChange} style={{ width: 'auto' }}>
                            <option value="LEARN_MORE">Learn More</option>
                            <option value="NO_BUTTON">No Button</option>
                            <option value="WATCH_MORE">Watch More</option>
                        </select>
                    </div>

                </div>

                <div className="col-12 col-lg-8  mb-3">
                    
                        {selectedPage && (
                            <label htmlFor="Page-select">{selectedPage.id}</label>
                        )}
            
                        {/* Nếu chưa chọn page */}
                        {!selectedPage && (
                            <label htmlFor="Page-select">Page ID</label>
                        )}
                        <div className="card">
                            <textarea className="form-control" id="status" rows="4" name="status" type="text" placeholder="What's on your mind?" onChange={handleInputChange} />
                        </div>
                        {image && (
                        <div style={{ marginTop: '20px' }}>
                            <img 
                                src={image} 
                                alt="Selected" 
                                style={{ 
                                    width: '100%', 
                                    maxWidth: '690px', 
                                    maxHeight: '690px', 
                                    objectFit: 'cover', 
                                    height: '100%', 
                                    objectPosition: 'center' 
                                }} 
                            />

                        </div>
                        )}
                        <div className="d-flex justify-content-between card-body" style={{ backgroundColor: '#f0f2f5' }}>
                            <div>
                                <div className="post_fake_domain text-uppercase text-muted js_fake_domain_show">{fakeDomain}</div>
                                <div className="post_title text-muted js_title_show" style={{ fontWeight: "bold" }}>{title}</div>
                                <div className="post_description text-muted js_description_show">{description}</div>

                            </div>
                            <div className="post_action">
                                <button className="btn btn-light js_type_button_show">
                                    {buttonType.replace("_", " ")}
                                </button>


                            </div>
                        </div>
                        {/* Hiển thị loading khi loadingRef.current là true */}
                        {loadingRef.current && <div className="loading-spinner">Waiting...</div>}
                        
                        <div className="row row5" style={{ maxWidth: '1024px' }}>
                            {/* Các thành phần khác */}
                            <button className="btn btn-success" onClick={requestCreatPost} disabled={loadingRef.current}>
                                Publish
                            </button>
                        </div>
                        {urlPost && (
                        <a href={urlPost} target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                            <div className="row row5" style={{ maxWidth: '1024px' }}>
                                
                                <button className="btn btn-primary">
                                        Your Post
                                </button>
                                
                            </div>
                        </a>
                        )}

                    
                </div>
            </div>
            </CCardBody>
        </CCard>
    );
};

export default ImagePost;
