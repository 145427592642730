import React, { createContext, useEffect, useState } from 'react';
import { asyncDispatchEvent, getAccessToken, getAdAccounts, getPages } from 'src/pages/FBTool/functions';
import notify from 'src/utils/notify';
import FbToolContent from 'src/pages/FBTool/FBToolContent';

const ACCESS_TOKEN_KEY = 'accessToken';
const ADS_TOKEN_KEY = 'adsToken';


export const FBToolContext = createContext({});

function FBTool(props) {

    const [accessToken, setAccessToken] = useState(localStorage.getItem(ACCESS_TOKEN_KEY) || '');
    const [adsTokens, setAdsTokens] = useState({});
    const [pageToken, setPageToken] = useState(null);
    const [pages, setPages] = useState({});
    const [selectedPage, setSelectedPage] = useState(null);
    const [selectedAdAccount, setSelectedAdAccount] = useState(null);
    const [adAccounts, setAdAccounts] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [params, setParams] = useState({
        selectedPage: null,
        selectedAdAccount: null,
        limit: 10,
    });

    useEffect(() => {
        init().then();

    }, []);

    useEffect(() => {
        if (accessToken) {
            initToken().then();
        } else {
            removeInitToken();
        }
    }, [accessToken]);

    async function initToken() {
        setIsLoading(true);
        await fetchPages();
        await fetchAdAccounts();
        setIsLoading(false);
    }

    function removeInitToken() {
        setPages({});
        setAdAccounts({});
    }

    //
    // useEffect(() => {
    //     if (!params.page_id) {
    //         return;
    //     }
    //
    // }, [params]);



    async function init() {
        const token = await getAccessToken();
        if (!token) {
            notify.error('Error get token, please install extension and login facebook before using this tool');
            return;
        }
        setAccessToken(token);
    }


    async function getAdsTokens(args) {
        const act_id = args?.act_id ?? params?.act_id;
        if (adsTokens[act_id]) return adsTokens[act_id];
        try {
            const { token } = await asyncDispatchEvent('getAdToken', {
                token: accessToken,
                act_id,
            });
            console.log({ token });
            setAdsTokens({
                ...adsTokens,
                [act_id]: token,
            });
            return token;
        } catch (e) {
            return false;
        }

    }

    async function getPageToken() {
        if (!selectedPage) return;
        const token = accessToken;
        const response = await asyncDispatchEvent('getPageToken', { token, pageId: selectedPage });
        return response.data;
    }

    async function fetchPages() {
        const token = accessToken;
        const response = await getPages({ token });
        const pageInfo = (response.data || []).reduce((acc, page) => {
            acc[page.id] = page;
            return acc;
        }, {});
        setPages(pageInfo);
    }

    async function fetchAdAccounts() {
        const token = accessToken;
        const response = await getAdAccounts({ token });
        console.log('[AdAccounts]', response.data);
        const adAccountInfo = (response.data || []).reduce((acc, adAccount) => {
            acc[adAccount.id] = adAccount;
            return acc;
        }, {});
        setAdAccounts(adAccountInfo);
    }

    async function getUseInfo() {

    }


    const data = {
        accessToken,
        setAccessToken,
        pages,
        setPages,
        selectedPage,
        setSelectedPage,
        selectedAdAccount,
        setSelectedAdAccount,
        adAccounts,
        setAdAccounts,
        params,
        setParams,
        isLoading,
        setIsLoading,
        getAdsTokens,


    };

    return (
        <FBToolContext.Provider value={data}>
            <FbToolContent />
        </FBToolContext.Provider>
    );
}

export default FBTool;