import notify from 'src/utils/notify';

export function asyncDispatchEvent(eventName, data = {}, timeout = 10000) {
    console.log('[DispatchEvent]', eventName, data);
    const eventResponse = `${eventName}Response`;
    return new Promise((resolve, reject) => {
        function handleEventResponse(event) {
            console.log(eventResponse, event.detail);
            resolve(event.detail);
            window.removeEventListener(eventResponse, handleEventResponse);
        }

        window.addEventListener(eventResponse, handleEventResponse);

        const event = new CustomEvent(eventName, {
            detail: data,
        });

        window.dispatchEvent(event);

        setTimeout(() => {
            reject('Token request timed out');
            window.removeEventListener(eventName, handleEventResponse);
        }, timeout);
    });
}

export async function getAccessToken() {
    let token = localStorage.getItem('accessToken');
    if (token) {
        return token;
    }
    try {
        const response = await asyncDispatchEvent('getAccessToken');
        token = response.token;
        localStorage.setItem('accessToken', token);
        return token;
    } catch (e) {
        return false;
    }
}

export async function getPageVideos({ token, page_id, limit = 10 }) {
    const response = await asyncDispatchEvent('getPageVideos', { token, pageId: page_id, limit });
    return response.data ?? [];
}

export async function getPages({ token }) {
    return await asyncDispatchEvent('getPages', { token });
}

export async function getAdAccounts({ token }) {
    return await asyncDispatchEvent('getListAdsAccounts', { token });
}

export function show_permalink_url(url){
    return 'https://www.facebook.com' + url
}